import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { Input, Select, Space } from 'antd'

import { ShopSelect } from '../../components/ShopSelect'
import { STATUS, STATUS2, TAB_TYPE, TAB_TYPE2 } from '../consts'
import { useORDER_STATUS_NAME_MAP } from '../utils'
import { Ctx } from './Ctx'
import { useColumnInfo } from './useColumnInfo'

const cTabTypeFilterStautsMap = {
  [TAB_TYPE.待匹配]: [STATUS.未付款, STATUS.已付款, STATUS.部分退款, STATUS.已退款],
  [TAB_TYPE.待付款]: [STATUS.未付款],
  [TAB_TYPE.待审核]: [STATUS.未付款, STATUS.已付款, STATUS.部分退款, STATUS.已退款],
  [TAB_TYPE.待处理]: [STATUS.未付款, STATUS.已付款, STATUS.部分退款, STATUS.已退款],
  [TAB_TYPE.已搁置]: [STATUS.未付款, STATUS.已付款, STATUS.部分退款, STATUS.已退款],
  [TAB_TYPE.待发货]: [STATUS.ShipO审核中, STATUS.待打包, STATUS.已出库],
  [TAB_TYPE.已发货或订单追踪]: [STATUS.已发货, STATUS.已签收],
  [TAB_TYPE.已取消]: [STATUS.未付款, STATUS.已付款, STATUS.部分退款, STATUS.已退款, STATUS.已取消],
  [TAB_TYPE.非ShipO]: [STATUS.未付款, STATUS.已付款, STATUS.部分退款, STATUS.已退款, STATUS.已取消],
  [TAB_TYPE.运单_无货]: [STATUS.ShipO审核中, STATUS.待打包, STATUS.已出库],
  [TAB_TYPE.运单_有货]: [STATUS.ShipO审核中, STATUS.待打包, STATUS.已出库],
  [TAB_TYPE.异常订单]: [STATUS.已驳回],
}

const eTabTypeFilterStautsMap = {
  [TAB_TYPE2.待审核]: [STATUS2.有货, STATUS2.无货],
  [TAB_TYPE2.待预报]: [STATUS2.待预报, STATUS2.预报失败],
  [TAB_TYPE2.待打印]: [STATUS2.待打印, STATUS2.打印失败],
  [TAB_TYPE2.待推送]: [STATUS2.待推送, STATUS2.推送失败],
  [TAB_TYPE2.待打包]: [STATUS2.待打包],
  [TAB_TYPE2.已发货或运单追踪]: [STATUS2.已发货, STATUS2.已签收],
  [TAB_TYPE2.已取消]: [],
  [TAB_TYPE2.异常履约单]: [],
}

/** 筛选条件 */
export const Filter = memo(function Filter() {
  const { t } = useTranslation()
  const tabType = useContextSelector(Ctx, v => v.tabType)
  const isEmployee = useContextSelector(Ctx, v => v.isEmployee)
  const orderStatus = useContextSelector(Ctx, v => v.orderStatus)
  const param = useContextSelector(Ctx, v => v.param)
  const sysShopIds = useContextSelector(Ctx, v => v.sysShopIds)
  const getList = useContextSelector(Ctx, v => v.getList)
  const setState = useContextSelector(Ctx, v => v.setState)
  const columnInfo = useColumnInfo()
  const nameMap = useORDER_STATUS_NAME_MAP(isEmployee)

  let valList: number[] = []
  if (!isEmployee) {
    valList = cTabTypeFilterStautsMap[tabType as TAB_TYPE] || []
  } else {
    valList = eTabTypeFilterStautsMap[tabType as TAB_TYPE2] || []
  }

  return (
    <Space size="middle">
      <div>
        {columnInfo.orderStatus.title}&nbsp;
        <Select
          popupMatchSelectWidth={false}
          options={[
            { value: -1, label: t('2-common.quan-bu') },
            ...valList.map(value => ({ value, label: nameMap[value] })),
          ]}
          value={orderStatus ?? -1}
          onChange={val => {
            const v = val === -1 ? undefined : val
            getList({ orderStatus: v, page: 1 })
          }}
        />
      </div>
      {!isEmployee && (
        <ShopSelect
          multiple
          value={sysShopIds}
          onChange={val => {
            const sysShopIds = val.length ? val : undefined
            getList({ sysShopIds, page: 1 })
          }}
        />
      )}
      <Input.Search
        style={{ minWidth: 300 }}
        placeholder={t('Order.qing-shu-ru-ding-dan-hao')}
        enterButton
        allowClear
        onChange={e => setState({ orderNumber: e.target.value.trim() })}
        onSearch={(val, _e, info) => {
          val = val.trim()
          if (info?.source === 'clear' && !param?.orderNumber) return
          getList({ orderNumber: val, page: 1 })
        }}
      />
    </Space>
  )
})
