import { memo, useState } from 'react'

import { PageEmpty } from '@/components/PageEmpty'
import { OrderPageLayout } from '../components/OrderPageLayout'
import { CtxProvider } from '../Ctx'
import { Filter } from './Filter'
import { OrderTable } from './OrderTable'
import { OrderTabs } from './OrderTabs'
import { DEFAULT_TAB, TABS } from './utils'
export const OrderTrack = memo(function OrderTrack() {
  const [activeTab, setActiveTab] = useState<string>(DEFAULT_TAB)
  if (TABS.includes(activeTab)) {
    return (
      <CtxProvider key={activeTab} tabType={+activeTab} activeTab={activeTab} setActiveTab={setActiveTab}>
        <OrderPageLayout headerFilter={<Filter />} table={<OrderTable />} tabs={<OrderTabs />} />
      </CtxProvider>
    )
  }
  return <PageEmpty type="notFound" />
})
