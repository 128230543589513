import { memo, useState } from 'react'

import { PageEmpty } from '@/components/PageEmpty'
import { OrderPageLayout } from '../components/OrderPageLayout'
import { CtxProvider, Filter } from '../Ctx'
import { Action } from './Action'
import { ManualOrder } from './ManualOrder'
import { OrderTable } from './OrderTable'
import { OrderTabs } from './OrderTabs'
import { DEFAULT_TAB, TABS } from './utils'

export const OrderList = memo(function OrderList() {
  const [activeTab, setActiveTab] = useState<string>(DEFAULT_TAB)
  const [isShow, setIsShow] = useState<boolean>(false)

  if (TABS.includes(activeTab)) {
    return (
      <CtxProvider key={activeTab} tabType={+activeTab} activeTab={activeTab} setActiveTab={setActiveTab}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <OrderPageLayout
            headerFilter={<Filter />}
            headerAction={<Action setIsShow={setIsShow} />}
            tabs={<OrderTabs />}
            table={<OrderTable />}
          />
          {isShow && (
            <ManualOrder
              onReturn={() => {
                setIsShow(false)
              }}
            />
          )}
        </div>
      </CtxProvider>
    )
  }
  return <PageEmpty type="notFound" />
})
