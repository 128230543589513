import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { produce } from 'immer'
import { Button, InputNumber, Popconfirm } from 'antd'
import { FormOutlined, PlusOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons'

import { CusTable } from '@/components/CusTable'
import { LocalProductCell } from '@biz/components/LocalProductCell'
import { LocalProductPickModal } from '@biz/components/LocalProductPick'
import {
  apiAddOrderLocalProduct,
  apiDeleteOrderLocalProduct,
  apiReplaceOrderLocalProduct,
  apiUpdateOrderLocalProduct,
} from '../../apis'
import type { IOrderDetail } from '../../interface'
import { Ctx } from './Ctx'

type IRecord = NonNullable<IOrderDetail['orderLocalProductList']>[number]

const CurrentQuantityCell = ({ data }: { data: IRecord }) => {
  const { t } = useTranslation()
  const sysShopId = useContextSelector(Ctx, v => v.sysShopId)
  const editable = useContextSelector(Ctx, v => v.editable)
  const setState = useContextSelector(Ctx, v => v.setState)
  const [eidting, setEditing] = useState(false)
  const [value, setValue] = useState(data.currentQuantity)

  return (
    <>
      {eidting ? (
        <InputNumber
          style={{ width: 60 }}
          autoFocus
          min={1}
          precision={0}
          value={value}
          onChange={v => setValue(v || 1)}
        />
      ) : (
        <span style={{ display: 'inline-block', minWidth: 33 }}>{data.currentQuantity}</span>
      )}

      {editable && (
        <>
          {eidting ? (
            <>
              <Button
                style={{ width: 25, marginLeft: 10 }}
                type="link"
                icon={<SaveOutlined />}
                onClick={async () => {
                  if (data.currentQuantity === value) {
                    setEditing(false)
                    return
                  }
                  try {
                    setState({ loading: t('Order.zheng-zai-bao-cun') })
                    await apiUpdateOrderLocalProduct({
                      sysShopId,
                      sysOrderId: data.sysOrderId,
                      localSkuId: data.skuId,
                      shopProductId: data.shopProductId,
                      currentQuantity: value,
                    })
                    setEditing(false)
                    setState(prev => {
                      const detail = prev.detail!
                      const nextDetail = produce(detail, draft => {
                        const target = draft.$localProductList.find(d => d.skuId === data.skuId)
                        if (target) Object.assign(target, { currentQuantity: value })
                      })
                      return { detail: nextDetail }
                    })
                  } finally {
                    setState({ loading: false })
                  }
                }}
              />
              <Button
                style={{ width: 25 }}
                type="link"
                icon={<RollbackOutlined />}
                onClick={() => {
                  setEditing(false)
                  setValue(data.currentQuantity)
                }}
              />
            </>
          ) : (
            <Button type="link" icon={<FormOutlined />} onClick={() => setEditing(true)} />
          )}
        </>
      )}
    </>
  )
}

/** 本地产品列表 */
export const CLocalProduct = memo(function CLocalProduct() {
  const { t } = useTranslation()
  const sysShopId = useContextSelector(Ctx, v => v.sysShopId)
  const sysOrderId = useContextSelector(Ctx, v => v.sysOrderId)
  const list = useContextSelector(Ctx, v => v.detail!.$localProductList)
  const editable = useContextSelector(Ctx, v => v.editable)
  const setState = useContextSelector(Ctx, v => v.setState)

  return (
    <CusTable<IRecord>
      rowKey="skuId"
      columns={[
        {
          width: '50%',
          title: t('Order.chan-pin-xin-xi'),
          key: '$localProductInfo',
          render: (_v, { imgUrl, sku, skuId, spu, productEnTitle }) => (
            <LocalProductCell img={imgUrl} sku={sku} skuId={skuId} spu={spu} title={productEnTitle} />
          ),
        },
        {
          width: '25%',
          title: t('Order.chan-pin-shu-liang'),
          render: (_v, record) => <CurrentQuantityCell data={record} />,
        },
        {
          width: '25%',
          align: 'center',
          title: editable && (
            <Button
              size="small"
              type="primary"
              ghost
              icon={<PlusOutlined />}
              onClick={() => {
                LocalProductPickModal.open({
                  disablePickSkuIds: list.map(d => d.skuId),
                  onPick: async ({ localProduct }) => {
                    const { skuId } = localProduct
                    await apiAddOrderLocalProduct({
                      sysShopId,
                      sysOrderId,
                      localSkuId: skuId,
                      currentQuantity: 1,
                    })
                    setState(prev => {
                      const detail = prev.detail!
                      const nextDetail = produce(detail, draft => {
                        draft.$localProductList.push({
                          ...localProduct,
                          shopProductId: null,
                          currentQuantity: 1,
                          price: '',
                          sysOrderId,
                        })
                      })
                      return { detail: nextDetail }
                    })
                  },
                })
              }}
            >
              {t('2-common.tian-jia')}
            </Button>
          ),
          hidden: !editable,
          render: (_v, record) => (
            <>
              <Button
                type="link"
                onClick={() => {
                  LocalProductPickModal.open({
                    disablePickSkuIds: list.map(d => d.skuId),
                    onPick: async ({ localProduct }) => {
                      const { skuId } = localProduct
                      await apiReplaceOrderLocalProduct({
                        sysShopId,
                        sysOrderId,
                        localSkuId: record.skuId,
                        replaceSkuId: skuId,
                        currentQuantity: record.currentQuantity,
                      })
                      setState(prev => {
                        const detail = prev.detail!
                        const nextDetail = produce(detail, draft => {
                          const index = draft.$localProductList.findIndex(d => d.skuId === record.skuId)
                          if (index > -1) {
                            draft.$localProductList.splice(index, 1, {
                              ...localProduct,
                              shopProductId: null,
                              currentQuantity: record.currentQuantity,
                              price: record.price,
                              sysOrderId,
                            })
                          }
                        })
                        return { detail: nextDetail }
                      })
                    },
                  })
                }}
              >
                {t('Order.geng-huan')}
              </Button>
              <Popconfirm
                title={t('2-common.que-ren-yao-shan-chu-ma')}
                onConfirm={() => {
                  const fn = async () => {
                    try {
                      setState({ loading: t('Order.zheng-zai-shan-chu') })
                      await apiDeleteOrderLocalProduct({
                        sysShopId,
                        sysOrderId,
                        localSkuId: record.skuId,
                        shopProductId: record.shopProductId,
                      })
                      setState(prev => {
                        const detail = prev.detail!
                        const nextDetail = produce(detail, draft => {
                          const index = draft.$localProductList.findIndex(d => d.skuId === record.skuId)
                          if (index > -1) draft.$localProductList.splice(index, 1)
                        })
                        return { detail: nextDetail }
                      })
                    } finally {
                      setState({ loading: false })
                    }
                  }
                  fn()
                }}
              >
                <Button type="link" danger disabled={list.length === 1}>
                  {t('2-common.shan-chu')}
                </Button>
              </Popconfirm>
            </>
          ),
        },
      ]}
      dataSource={list}
    />
  )
})
