import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import { Descriptions, List, Popover, Space, Tag, Timeline, Tooltip } from 'antd'
import { FileDoneOutlined, QuestionCircleOutlined, ShoppingOutlined, TruckOutlined } from '@ant-design/icons'

import { CellFormatSum } from '@/components/CellFormatSum'
import { toThousands } from '@/utils/utils'
import { MultilineTextShow } from '../../components/MultilineTextShow'
import { PlatFormShow } from '../../components/PlatFormShow'
import { ProductCell } from '../../components/ProductCell'
import { AddressInfoShow, OrderDetailDrawer } from '../components/OrderDetail'
import { OrderStatusShow } from '../components/OrderStatusShow'
import { OrderTags } from '../components/OrderTags'
import { STATUS, STATUS2, TAB_TYPE } from '../consts'
import type { IOrder } from '../interface'
import { Ctx } from './Ctx'
import yunexpressLogo from '@/assets/yunexpressLogo.png'

type IProps = { record: IOrder }

const useAction = (sysOrderId: string) => {
  const getList = useContextSelector(Ctx, v => v.getList)
  const deleteRecord = useContextSelector(Ctx, v => v.deleteRecord)
  const refreshTabShowCounts = useContextSelector(Ctx, v => v.refreshTabShowCounts)
  const deleteItem = () => {
    deleteRecord(sysOrderId)
    refreshTabShowCounts()
  }
  const refreshList = () => {
    getList()
    refreshTabShowCounts()
  }
  return { deleteItem, refreshList }
}

/** 订单号单元格 */
export const OrderNumberCell = ({ record }: IProps) => {
  const { orderNumber, $parentKey } = record
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
      <div style={{ flex: '1 1', overflow: 'hidden', lineHeight: 1.1 }}>{orderNumber}</div>
      {!$parentKey && <OrderTags data={record} style={{ flex: '0 0 auto' }} />}
    </div>
  )
}

/** 商品信息单元格 */
export const ProductInfoCell = ({ record }: IProps) => {
  const { t } = useTranslation()
  const { lineItems, orderStockStatus } = record
  return (
    <>
      <div className="g-ellipsis">
        <Popover
          content={
            <List
              style={{ maxWidth: 500, maxHeight: 300, overflow: 'auto' }}
              size="small"
              dataSource={lineItems}
              renderItem={({ imgUrl, sku, currentQuantity }) => (
                <List.Item>
                  <ProductCell img={imgUrl} sku={`${sku} * ${currentQuantity}`} />
                </List.Item>
              )}
            />
          }
        >
          <span>
            {lineItems[0]?.sku || '--'}
            {lineItems.length > 1 && ' ...'}
          </span>
        </Popover>
      </div>
      {orderStockStatus === 0 && <Tag color="red">{t('Order.que-huo')}</Tag>}
    </>
  )
}

/** 渠道单元格 */
export const ChannelCell = ({ record }: IProps) => {
  const { platForm, shopName } = record
  return (
    <>
      <PlatFormShow platForm={platForm} style={{ marginRight: 4 }} />
      <span>{shopName}</span>
    </>
  )
}

/** 订单金额单元格 */
export const CurrentTotalPriceCell = ({ record }: IProps) => {
  return <CellFormatSum value={record.currentTotalPrice} currency={record.currency || null} mode="inline" />
}

/** 收件人单元格 */
export const OrderAddressCell = ({ record }: IProps) => {
  const { country, name } = record.orderAddress
  return (
    <Popover overlayStyle={{ maxWidth: 500 }} content={<AddressInfoShow values={record.orderAddress} />}>
      <span>
        {name || '--'}[{country || '--'}]
      </span>
    </Popover>
  )
}

/** 订单时间单元格 */
export const OrderTimeCell = ({ record }: IProps) => {
  const { t } = useTranslation()
  const isEmployee = useContextSelector(Ctx, v => v.isEmployee)
  const { orderStatus, createdAt, fulfillmentTime, deliveryTime } = record
  const format = ` MM-DD HH:mm`
  return (
    <Space direction="vertical" size={6}>
      <div>
        <Tooltip
          title={
            !isEmployee && orderStatus === STATUS.未付款
              ? t('Order.xia-dan-shi-jian')
              : t('Order.xia-dan-fu-kuan-shi-jian')
          }
        >
          <ShoppingOutlined />
          {dayjs(createdAt).format(format)}
        </Tooltip>
      </div>
      {fulfillmentTime && (
        <div>
          <Tooltip title={t('Order.ti-jiao-lv-yue-shi-jian')}>
            <FileDoneOutlined />
            {dayjs(fulfillmentTime).format(format)}
          </Tooltip>
        </div>
      )}
      {deliveryTime && (
        <div>
          <Tooltip title={t('Order.fa-huo-shi-jian')}>
            <TruckOutlined />
            {dayjs(deliveryTime).format(format)}
          </Tooltip>
        </div>
      )}
    </Space>
  )
}

/** 物流方式单元格 */
export const FulfillmentCell = ({ record }: IProps) => {
  const { t } = useTranslation()
  const tabType = useContextSelector(Ctx, v => v.tabType)
  const isEmployee = useContextSelector(Ctx, v => v.isEmployee)
  const { channelCode, estimatedFreight, registrationFreight, subOrders } = record
  const { channelEName } = record
  const { deleteItem, refreshList } = useAction(record.sysOrderId)
  // 父订单
  if (subOrders?.length) return
  // 商家侧-非ShipO
  if (!isEmployee && tabType === TAB_TYPE.非ShipO) return
  // 商家侧-待处理-请选择物流方式
  if (!isEmployee && tabType === TAB_TYPE.待处理 && !channelCode) {
    return (
      <a
        style={{ cursor: 'pointer' }}
        onClick={() => {
          OrderDetailDrawer.open({
            ...record,
            defaultActiveTab: 'logistics',
            onOperationSuccess: shouldCall => {
              if (shouldCall === 'deleteItem') deleteItem()
              else if (shouldCall === 'refreshList') refreshList()
            },
          })
        }}
      >
        {t('Order.qing-xuan-ze-wu-liu-fang-shi')}
      </a>
    )
  }
  // 其它
  if (channelCode) {
    return (
      <Space direction="vertical" size={4}>
        <Popover
          overlayStyle={{ maxWidth: 500 }}
          content={
            <Descriptions
              size="small"
              bordered
              column={1}
              items={[
                {
                  label: t('Order.wu-liu-ming-cheng'),
                  children: channelEName || '--',
                },
                {
                  label: t('Order.qu-dao-dai-ma'),
                  children: channelCode || '--',
                },
                {
                  label: t('Order.yun-fei'),
                  children: <span style={{ color: 'red' }}>{toThousands(estimatedFreight)}</span>,
                },
                {
                  label: t('Order.gua-hao-fei'),
                  children: toThousands(registrationFreight),
                },
              ]}
            />
          }
        >
          <Space style={{ lineHeight: 1.1 }} direction="vertical" size={6}>
            <div>
              {t('Order.wu-liu-fang-shi')}: <img style={{ height: '1em' }} src={yunexpressLogo} />
            </div>
            {estimatedFreight && registrationFreight && (
              <div>
                {t('Order.wu-liu-fei')}: {BigNumber.sum(estimatedFreight, registrationFreight).toFormat(2)}
              </div>
            )}
          </Space>
        </Popover>
      </Space>
    )
  }
}

/** 历史路由事件 */
export const OrderTrackRouteCell = ({ record }: IProps) => {
  const { trackLatestEvent, trackEvents, trackLatestLocation, trackLatestTime } = record
  const timeItem = trackEvents?.map((item, index) => {
    return {
      ...item,
      children: (
        <>
          <div>{item.event}</div>
          <div>
            {item.location} {item.time ? dayjs(item.time).format('YYYY-MM-DD HH:mm') : ''}
          </div>
        </>
      ),
      color: index === 0 ? 'green' : 'gray',
    }
  })
  return (
    <Popover
      overlayInnerStyle={{ width: 500, maxHeight: 300, overflow: 'auto' }}
      content={<Timeline items={timeItem} />}
    >
      <div>
        <div className="g-ellipsis">{trackLatestEvent}</div>
        <div>
          {trackLatestLocation} {trackLatestTime ? dayjs(trackLatestTime).format('YYYY-MM-DD HH:mm') : ''}
        </div>
      </div>
    </Popover>
  )
}

/** 状态单元格 */
export const OrderStatusCell = ({ record }: IProps) => {
  const { orderStatus, refusalReason, failReason } = record
  const isEmployee = useContextSelector(Ctx, v => v.isEmployee)
  if (!isEmployee) {
    if (orderStatus === STATUS.已驳回) {
      return (
        <Popover overlayStyle={{ maxWidth: 360 }} content={MultilineTextShow.toJsx(refusalReason || '--')}>
          <OrderStatusShow orderStatus={orderStatus} isEmployee={isEmployee} after={<QuestionCircleOutlined />} />
        </Popover>
      )
    }
  }
  if (isEmployee) {
    if (orderStatus === STATUS2.预报失败 || orderStatus === STATUS2.打印失败 || orderStatus === STATUS2.推送失败) {
      return (
        <Popover overlayStyle={{ maxWidth: 360 }} content={MultilineTextShow.toJsx(failReason || '--')}>
          <OrderStatusShow orderStatus={orderStatus} isEmployee={isEmployee} after={<QuestionCircleOutlined />} />
        </Popover>
      )
    }
  }
  return <OrderStatusShow orderStatus={orderStatus} isEmployee={isEmployee} />
}

/** 操作单元格 */
export { CellAction as ActionCell } from './Cell.action'
