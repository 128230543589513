import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'

import { TFormValues } from '../interface'
/** 文本输入字段 */
export const textInputFields = [
  'sku',
  'spu',
  'productEnTitle',
  'productCTitle',
  'upc',
  'fnsku',
  'otherCode',
  'model',
  'brand',
  'purchasePrice',
  'hsCode',
  'ematerial',
  'cmaterial',
  'epurpose',
  'cpurpose',
  'otherDeclarationElements',
]

/** 根据详情创建初始的表单数据 */
export const createInitialFormValues = (): TFormValues => {
  console.log("dayjs().format('YYYY-MM-DD HH:mm:ss')", dayjs().format('YYYY-MM-DD HH:mm:ss'))
  // 默认值尽可能使用空串
  const values: TFormValues = {
    addressInfo: {
      address1: '',
      city: '',
      country: '',
      countryCode: '',
      name: '',
      phone: '',
      province: '',
      zip: '',
      address2: '',
      company: '',
    },
    orderInfo: {
      orderNumber: '',
      sysShopId: null,
      createdAt: dayjs().format($F_YMDHms),
      email: '',
      shippingMethod: '',
      stash: '微仓',
      buyerNote: '',
      sellerNote: '',
      sellFreight: '',
    },
    productList: [],
    currencyCode: 'USD',
  }

  return values
}

/** 根据表单数据创建提交数据 */
export const createSendValues = ({ ...formValues }: TFormValues): TFormValues => {
  const emptyValues = createInitialFormValues()
  Object.entries(emptyValues).forEach(([key, val]) => {
    if (typeof val === 'string') {
      const obj = formValues as any
      obj[key] = obj[key] || ''
    }
  })

  return formValues
}

/** 创建空的产品数据 */
export const createEmptyProduct = (): {
  currencyCode: string
  currentQuantity: number
  price: string
  shopProductId: string
  shopSkuId: string
} => {
  return {
    currencyCode: '',
    currentQuantity: 1,
    price: '',
    shopProductId: '',
    shopSkuId: '',
  }
}

/**
 * 计算 JSON 数据中金额的总和
 * @param {Array<{ [key: string]: any }>} data - 包含金额的对象数组
 * @param {string} amountKey - 金额字段的键名
 * @returns {BigNumber} - 总金额
 */
export function calculateTotalAmount(
  data: Array<{ [key: string]: any }>,
  amountKey: string,
  currentQuantity: number | string,
): BigNumber | any {
  return data.reduce((total, item) => {
    const amount = new BigNumber(item[amountKey] || 0).multipliedBy(new BigNumber(item[currentQuantity])) // 确保金额为 BigNumber 实例
    return total.plus(amount) // 累加金额
  }, new BigNumber(0)) // 初始化为 0
}
