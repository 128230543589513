import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'

import { selectFilterOption } from '@/utils/utils'
import { IPlatFormProduct } from '../interface'

/** 供应商本地产品选择器 */
export const PlatformProductSelect = ({
  style,
  productList,
  skuId,
  sku,
  disableSkuIds,
  onPick,
}: {
  style?: CSSProperties
  productList: IPlatFormProduct[]
  skuId: string
  /** 用于回显 */
  sku: string | null
  disableSkuIds: string[]
  onPick: (product: IPlatFormProduct) => void
}) => {
  const { t } = useTranslation()
  return (
    <Select
      style={style}
      status={!skuId ? 'error' : undefined}
      placeholder={t('2-common.qing-xuan-ze')}
      showSearch={true}
      popupMatchSelectWidth={false}
      filterOption={selectFilterOption}
      options={productList?.map(d => ({
        value: d.skuId,
        label: d.sku || d.skuId,
        $name: d.sku || d.skuId,
        disabled: skuId === d.skuId ? false : disableSkuIds.includes(d.skuId),
      }))}
      value={(productList.some(d => d.skuId === skuId) ? skuId : sku || skuId) || undefined}
      onChange={val => {
        const opt = productList.find(o => o.skuId === val)
        if (opt) onPick(opt)
      }}
    />
  )
}
