import { MutableRefObject, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { useMemoizedFn } from 'ahooks'
import { Button, Divider, Drawer, Space } from 'antd'

import { PlatFormShow } from '@biz/components/PlatFormShow'
import { globalPopupSlot } from '@biz/globalPopupSlot'
import { CAction } from './CAction'
import { Ctx, CtxProvider, ICtxVal } from './Ctx'
import { IOrderDetailProps } from './interface'
import { Detail } from './OrderDetail'

const updatePopup = globalPopupSlot.insert(null)

const DetailDrawer = ({
  onHideRef,
  destroy,
}: {
  onHideRef: MutableRefObject<(() => void) | null>
  destroy: () => void
}) => {
  const { t } = useTranslation()
  const { open, onHide, afterOpenChange } = globalPopupSlot.useAntdPopupAnimation(destroy)
  const platForm = useContextSelector(Ctx, v => v.platForm)
  const shopName = useContextSelector(Ctx, v => v.shopName)
  const orderNumber = useContextSelector(Ctx, v => v.orderNumber)
  const hasDetail = useContextSelector(Ctx, v => !!v.detail)

  onHideRef.current = onHide

  return (
    <Drawer
      title={
        <Space size={4}>
          <PlatFormShow platForm={platForm} />
          <span>{shopName}</span>
          <Divider type="vertical" style={{ margin: '0 4px' }} />
          <span>{orderNumber}</span>
        </Space>
      }
      width={900}
      open={open}
      onClose={onHide}
      afterOpenChange={afterOpenChange}
      extra={
        <Space>
          {hasDetail && <CAction />}
          <Button onClick={onHide}>{t('2-common.guan-bi')}</Button>
        </Space>
      }
    >
      <Detail />
    </Drawer>
  )
}

/** 订单详情抽屉组件（外部使用） */
export const OrderDetailDrawer = Object.assign(
  ({ onOperationSuccess, destroy, ...rest }: IOrderDetailProps & { destroy: () => void }) => {
    const { pathname } = useLocation()
    const initialPathname = useRef(pathname).current
    const onHideRef = useRef<null | (() => void)>(null)

    const handleOperationSuccess = useMemoizedFn<NonNullable<ICtxVal['onOperationSuccess']>>((...args) => {
      onHideRef.current?.()
      return onOperationSuccess?.(...args)
    })

    useEffect(() => {
      if (initialPathname !== pathname) updatePopup(null)
    }, [destroy, initialPathname, pathname])

    return (
      <CtxProvider {...rest} onOperationSuccess={handleOperationSuccess}>
        <DetailDrawer destroy={destroy} onHideRef={onHideRef} />
      </CtxProvider>
    )
  },
  {
    open: (props: IOrderDetailProps) => {
      updatePopup(<OrderDetailDrawer {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
