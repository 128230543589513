import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TRACK_TAB_TYPE } from '../consts'

/** 默认页签 */
export const DEFAULT_TAB: string = TRACK_TAB_TYPE.未查到 + ''

/** 所有页签值 */
export const TABS: string[] = [
  TRACK_TAB_TYPE.未查到,
  TRACK_TAB_TYPE.待取件,
  TRACK_TAB_TYPE.运输中,
  TRACK_TAB_TYPE.到达待取,
  TRACK_TAB_TYPE.派件中,
  TRACK_TAB_TYPE.投递失败,
  TRACK_TAB_TYPE.已签收,
  TRACK_TAB_TYPE.可能异常,
  TRACK_TAB_TYPE.运输过久,
].map(v => v + '')

/** 页签名 map */
export const useTAB_NAME_MAP = (): Record<string, string | undefined> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      [TRACK_TAB_TYPE.未查到]: '未查到',
      [TRACK_TAB_TYPE.待取件]: '待取件',
      [TRACK_TAB_TYPE.运输中]: '运输中',
      [TRACK_TAB_TYPE.到达待取]: '到达待取',
      [TRACK_TAB_TYPE.派件中]: '派件中',
      [TRACK_TAB_TYPE.投递失败]: '投递失败',
      [TRACK_TAB_TYPE.已签收]: '已签收',
      [TRACK_TAB_TYPE.可能异常]: '可能异常',
      [TRACK_TAB_TYPE.运输过久]: '运输过久',
    }),
    [t],
  )
}
