import axios, { AxiosRequestConfig } from 'axios'
import _ from 'lodash'
import qs from 'qs'

import { downloadFromServer } from '@/utils/download'
import { ISuccessAndFailResultModalProps } from '../components/SuccessAndFailResultModal'
import { apiGetRecords } from '../ShopList/apis'
import { TAB_TYPE } from './consts'
import type { ICtxVal } from './Ctx'
import { IOrder, IOrderDetail, IOrderListReqData, IOrderListRes } from './interface'
import './__mock__'

/** 导出商店列表 */
export { apiGetRecords }

/* ================== 公共 ================== */

/** 拦截运单 */
export const apiLogisticsIntercept = async (sysOrderId: string) => {
  await axios.get(`/linkhub_oms/order/logistics/intercept/${sysOrderId}`)
}

/** 物流轨迹查询 */
export const apiLogisticsTracking = async (sysOrderId: string) => {
  return axios
    .get(`/linkhub_oms/logistic/logistics_tracking`, { params: { sysOrderId } })
    .then(res => res.$data as string | null)
}

/** 同步订单 */
export const apiSyncOrder = async (): Promise<null | {
  /** 同步数量 */
  total: number | null
}> => {
  return axios.post('/linkhub_oms/order/customer/sync').then(res => res.$data)
}

/** 查询订单可选的物流列表 */
export const apiGetOptionalLogistics = async (
  sysOrderId: string,
): Promise<
  Array<{
    channelCode: string
    channelEName: string
  }>
> => {
  return axios.get(`/linkhub_oms/order/logistics/${sysOrderId}`).then(res => res.$data || [])
}

/** 查询物流渠道预估运费 */
export const apiGetEstimatedFreight = async (
  sysOrderId: string,
  channelCode: string,
): Promise<Pick<IOrder, 'estimatedFreight' | 'registrationFreight'>> => {
  return axios
    .get(`/linkhub_oms/order/estimated_freight/${sysOrderId}`, { params: { channelCode } })
    .then(res => res.$data)
}

/** 新增或修改订单物流渠道 */
export const apiAddOrEditLogistics = async (
  sysOrderId: string,
  channelCode: string,
  estimatedFreight: string,
  registrationFreight: string,
) => {
  await axios.post(`/linkhub_oms/order/logistics/${sysOrderId}`, { channelCode, estimatedFreight, registrationFreight })
}

/* ================== 共享 ================== */

/**
 * 获取订单列表
 * @param isEmployee 是否员工侧
 */
export const apiGetOrderList = async (
  isEmployee: boolean,
  params: IOrderListReqData,
  signal?: AxiosRequestConfig['signal'],
): Promise<IOrderListRes> => {
  let url
  if (isEmployee) url = '/linkhub_oms/order/employee/list'
  else url = '/linkhub_oms/order/customer/list'
  return axios.get(url, { params, signal }).then(res => {
    const data: IOrderListRes = _.cloneDeep(res.$data) // 用副本避免副作用

    for (const item of data.records || []) {
      if (item.subOrders?.length) {
        // 把商品信息合入父级
        item.lineItems = _.cloneDeep(item.subOrders.map(d => d.lineItems).flat(1))
        item.subOrders.forEach(d => {
          d.$parentKey = item.sysOrderId // 扩展 $parentKey
          delete d.subOrders
        })
        // 不可还原标识 && 子项过滤
        let $unRestorable
        item.subOrders = item.subOrders.filter(d => {
          if (item.orderStatus !== d.orderStatus /* 与父行状态不一致则说明该子订单已流转到其它 */) {
            $unRestorable = true
            return false
          }
          return true
        })
        if ($unRestorable === true) {
          item.$unRestorable = $unRestorable
        }
      } else {
        delete item.subOrders
      }
    }
    return data
  })
}

/**
 * 运单追踪列表
 * @params 查询条件
 */
export const apiGetTrackList = async (
  params: IOrderListReqData,
  signal?: AxiosRequestConfig['signal'],
): Promise<IOrderListRes> => {
  return axios.get('/linkhub_oms/order/track/list', { params, signal }).then(res => {
    const data: IOrderListRes = _.cloneDeep(res.$data) // 用副本避免副作用

    for (const item of data.records || []) {
      if (item.subOrders?.length) {
        // 把商品信息合入父级
        item.lineItems = _.cloneDeep(item.subOrders.map(d => d.lineItems).flat(1))
        item.subOrders.forEach(d => {
          d.$parentKey = item.sysOrderId // 扩展 $parentKey
          delete d.subOrders
        })
        // 不可还原标识 && 子项过滤
        let $unRestorable
        item.subOrders = item.subOrders.filter(d => {
          if (item.orderStatus !== d.orderStatus /* 与父行状态不一致则说明该子订单已流转到其它 */) {
            $unRestorable = true
            return false
          }
          return true
        })
        if ($unRestorable === true) {
          item.$unRestorable = $unRestorable
        }
      } else {
        delete item.subOrders
      }
    }
    return data
  })
}

/**
 * 追踪页签显示数量
 */
export const apiGetOrderTrackCount = async (): Promise<ICtxVal['tabShowCounts']> => {
  return axios.get('/linkhub_oms/order/track/tab/count', {}).then(res => res.$data)
}

/** 导出运单 */
export const apiTrackExport = async (params: { sysShopIds: string[] | undefined }): Promise<void> => {
  await downloadFromServer({
    method: 'get',
    url: `/linkhub_oms/order/track/export?${qs.stringify(params)}`,
    defaultFilename: 'Waybill',
  })
}

/**
 * 订单页签显示数量
 * @param isEmployee 是否员工侧
 */
export const apiGetOrderCount = async (
  isEmployee: boolean,
  params: {
    /**
     * - 商家侧：1-订单列表;2-运单;3-订单追踪;4-异常订单;
     * - 员工侧：1-履约单审核;4-异常履约单;
     */
    menuId: number
  },
): Promise<ICtxVal['tabShowCounts']> => {
  let url
  if (isEmployee) url = '/linkhub_oms/order/employee/tab/count'
  else url = '/linkhub_oms/order/customer/tab/count'
  return axios.get(url, { params }).then(res => res.$data)
}

/**
 * 订单详情
 * @param isEmployee 是否员工侧
 */
export const apiGetOrderDetail = async (
  isEmployee: boolean,
  params: Pick<IOrder, 'sysShopId' | 'sysOrderId'>,
): Promise<IOrderDetail> => {
  let promise
  if (isEmployee) {
    promise = axios.get(`/linkhub_oms/order/employee/detail/${params.sysOrderId}`)
  } else {
    promise = axios.get('/linkhub_oms/order/customer/detail', { params })
  }
  return promise.then(res => {
    const data = res.$data as Omit<IOrderDetail, `$${string}`>
    const { sysOrderId, customerBusinessStatus, employeeBusinessStatus, orderProductMatchList, orderLocalProductList } =
      data

    let $localProductList = orderLocalProductList || []
    if (!orderLocalProductList?.length && orderProductMatchList?.length) {
      $localProductList = orderProductMatchList
        .filter(d => d.localSkuId)
        .map<IOrderDetail['$localProductList'][number]>(d => ({
          sysOrderId,
          skuId: d.localSkuId!,
          sku: d.localSku,
          spu: d.localSpu,
          productEnTitle: d.localProductEnTitle,
          imgUrl: d.localImgUrl,
          shopProductId: d.productId,
          currentQuantity: d.currentQuantity,
          price: d.price,
        }))
    }

    return <IOrderDetail>{
      ...data,
      customerBusinessStatus: customerBusinessStatus ?? -1,
      employeeBusinessStatus: employeeBusinessStatus ?? -1,
      $businessStatuss: isEmployee ? employeeBusinessStatus : customerBusinessStatus,
      $localProductList,
    }
  })
}

/* ================== 商家侧 ================== */

/** 变更业务状态操作（移入待审核、审核通过、作废订单、搁置订单、还原搁置订单-不需要传changeBizStatus） */
export const apiChangeOrderState = async (reqData: {
  /** 当前业务状态 */
  curBizStatus: TAB_TYPE
  /** 变更业务状态 */
  changeBizStatus?: TAB_TYPE
  /** 相关订单 */
  shopOrders: Array<{
    sysShopId: IOrder['sysShopId']
    sysOrderId: IOrder['sysOrderId']
  }>
}) => {
  await axios.put('/linkhub_oms/order/customer/state', reqData)
}

/** 移入待处理 */
export const apiReviveProcess = async (params: Pick<IOrder, 'sysShopId' | 'sysOrderId'>) => {
  await axios.post('/linkhub_oms/order/customer/revive/process', null, { params })
}

/** 提交履约 */
export const apiInFulfillment = async (reqData: {
  /** 相关订单 */
  shopOrders: Array<{
    sysShopId: IOrder['sysShopId']
    sysOrderId: IOrder['sysOrderId']
  }>
}): Promise<ISuccessAndFailResultModalProps['result']> => {
  return axios.post('/linkhub_oms/order/customer/fulfillment', reqData).then(res => res.$data)
}

/** 订单地址更新 */
export const apiUpdateOrderAddress = async (
  reqData: IOrder['orderAddress'] & Pick<IOrder, 'sysShopId'> & { countryChangedFlag: boolean },
) => {
  await axios.post('/linkhub_oms/order/customer/address', reqData)
}

/** 订单主表信息更新 */
export const apiUpdateOrderMain = async (reqData: Pick<IOrder, 'sysShopId' | 'sysOrderId' | 'sellerNote'>) => {
  await axios.put('/linkhub_oms/order/customer', reqData)
}

/** 更换订单产品 */
export const apiReplaceOrderLocalProduct = async (
  reqData: Pick<IOrder, 'sysShopId' | 'sysOrderId'> & {
    localSkuId: string
    replaceSkuId: string
    currentQuantity: number
  },
) => {
  await axios.put('/linkhub_oms/order/customer/product/replace', reqData)
}

/** 新增订单产品 */
export const apiAddOrderLocalProduct = async (
  reqData: Pick<IOrder, 'sysShopId' | 'sysOrderId'> & {
    localSkuId: string
    currentQuantity: number
  },
) => {
  await axios.post('/linkhub_oms/order/customer/product', reqData)
}

/** 更新订单产品 */
export const apiUpdateOrderLocalProduct = async (
  reqData: Pick<IOrder, 'sysShopId' | 'sysOrderId'> & {
    localSkuId: string
    shopProductId: number | null
    currentQuantity: number
  },
) => {
  await axios.put('/linkhub_oms/order/customer/product', reqData)
}

/** 删除订单产品 */
export const apiDeleteOrderLocalProduct = async (
  reqData: Pick<IOrder, 'sysShopId' | 'sysOrderId'> & {
    localSkuId: string
    shopProductId: number | null
  },
) => {
  await axios.delete('/linkhub_oms/order/customer/product', { data: reqData })
}

/** 订单拆分 */
export const apiSplitOrder = async (
  reqData: Pick<IOrder, 'sysShopId' | 'sysOrderId'> & {
    packages: Array<{
      packageSkuList: Array<{
        localSkuId: string
        /** >=1 */
        count: number
      }>
    }>
  },
) => {
  await axios.post('/linkhub_oms/order/customer/resolution', reqData)
}

/** 还原订单 */
export const apiRestoreOrder = async (reqData: Pick<IOrder, 'sysShopId' | 'sysOrderId'>) => {
  await axios.post('/linkhub_oms/order/customer/restore', reqData)
}

/** 手工订单导入 */
export const manualOrderImport = async (
  file: File,
): Promise<{
  failList: { shopName: string; orderNumber: string; message: string }[]
  successTotal: number
  failTotal: number
}> => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(`/linkhub_oms/order/customer/manual/import`, formData).then(res => res.$data)
}

/** 创建手工订单 */
export const manualOrderCreate = async (reqData: any): Promise<void> => {
  return axios.post('/linkhub_oms/order/customer/manual/create', reqData)
}

/* ================== 员工侧 ================== */

/** 员工审核 */
export const apiApprovals = async (reqData: {
  sysOrderIds: string[]
  /** 0-审核不通过; 1-审核通过; */
  auditResult: number
  /** 拒审原因，auditResult 为 0 时必传 */
  refusalReason?: string
}) => {
  await axios.post('/linkhub_oms/order/employee/approvals', reqData)
}

/** 员工预报 */
export const apiForecast = async (reqData: {
  /** 相关订单 */
  shopOrders: Array<{
    sysShopId: IOrder['sysShopId']
    sysOrderId: IOrder['sysOrderId']
  }>
}): Promise<ISuccessAndFailResultModalProps['result']> => {
  return axios.post('/linkhub_oms/order/employee/forecast', reqData).then(res => res.$data)
}

/** 员工打印 */
export const apiPrint = async (reqData: {
  /** 相关订单 */
  shopOrders: Array<{
    sysShopId: IOrder['sysShopId']
    sysOrderId: IOrder['sysOrderId']
  }>
}): Promise<ISuccessAndFailResultModalProps['result']> => {
  return axios.post(`/linkhub_oms/order/employee/print`, reqData).then(res => res.$data)
}

/** 员工推送 */
export const apiPushToWms = async (reqData: {
  /** 相关订单 */
  shopOrders: Array<{
    sysShopId: IOrder['sysShopId']
    sysOrderId: IOrder['sysOrderId']
  }>
}): Promise<ISuccessAndFailResultModalProps['result']> => {
  return axios.post(`/linkhub_oms/order/employee/push_to_wms`, reqData).then(res => res.$data)
}
