import { useEffect, useId, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { useMemoizedFn } from 'ahooks'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import {
  Anchor,
  Button,
  Col,
  Divider,
  Form,
  FormProps,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
} from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { CusTable } from '@/components/CusTable'
import { FormItemDatePicker } from '@/components/FormItemDateTime'
import { useFormAllValues } from '@/hooks/hooks'
import { formAutoTrimOnBlur, selectFilterOption } from '@/utils/utils'
import { useCURRENCY_OPTS } from '../../../consts/consts'
import { useCOUNTRY_OPTS } from '../../../consts/country'
import { apiGetShopProductList } from '../../../ProductMatch'
import { apiGetRecords, manualOrderCreate } from '../../apis'
import { Ctx } from '../../Ctx'
import { IPlatFormProduct } from '../interface'
import { PlatformProductSelect } from './PlatformProductSelect'
import { calculateTotalAmount, createEmptyProduct, createInitialFormValues, textInputFields } from './utils'
import styles from './styles.module.less'
/** 手工订单 */
export const ManualOrder = ({
  onReturn,
}: {
  /** 返回 */
  onReturn: () => void
}) => {
  // 店铺筛选
  const [shopOption, setShopOption] = useState<{ label: string; value: string }[]>([])
  const [shopProductList, setShopProductList] = useState<IPlatFormProduct[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  const idPrefix = useId()
  const scrollRef = useRef<HTMLDivElement>(null)
  const [form] = Form.useForm<any>()
  const initialFormValues = useMemo(() => createInitialFormValues(), [])
  const allValues = useFormAllValues(form, initialFormValues)
  const countryOpts = useCOUNTRY_OPTS()
  const currencyOpts = useCURRENCY_OPTS()
  const getList = useContextSelector(Ctx, v => v.getList)
  const refreshTabShowCounts = useContextSelector(Ctx, v => v.refreshTabShowCounts)

  const formProps: FormProps<any> = {
    form,
    scrollToFirstError: { block: 'center', behavior: 'smooth' },
    layout: 'vertical',
    preserve: true,
    initialValues: initialFormValues,
    onBlur: e => formAutoTrimOnBlur({ event: e, form, includeField: textInputFields }),
  }

  const col1Props = { xxl: 6, sm: 8, xs: 8 }
  const col2Props = { xxl: 12, sm: 16, xs: 16 }

  /** 获取平台为shipo店铺列表 */
  const getShopList = useMemoizedFn(async () => {
    const list = await apiGetRecords({})
    const options = list
      .filter(item => item.platForm === 2)
      .map(item => {
        return {
          label: item.shopName,
          value: item.sysShopId,
        }
      })
    setShopOption(options)
  })

  /** 获取店铺对应的商品 */
  const getShopProductList = useMemoizedFn(async () => {
    const data = await apiGetShopProductList(allValues.orderInfo.sysShopId)
    setShopProductList((data as any) || [])
  })

  /** 订单商品金额 */
  const orderShopTotalPrice = useMemo(() => {
    return `${calculateTotalAmount(allValues.productList, 'price', 'currentQuantity')} `
  }, [allValues.productList])

  /** 订单总金额 */
  const orderTotalPrice = useMemo(() => {
    const totalPrice = calculateTotalAmount(allValues.productList, 'price', 'currentQuantity')
    const sellFreight = new BigNumber(allValues.orderInfo.sellFreight || 0)
    return `${totalPrice.plus(sellFreight)}`
  }, [allValues.orderInfo.sellFreight, allValues.productList])

  /** 校验订单号是否为空 */
  const validateProductList = (): boolean => {
    const { productList } = allValues
    if (!productList.length) {
      message.error(t('Delivery.qing-lu-ru-you-xiao-chan-pin'))
      return false
    }
    return true
  }

  /** 获取店铺列表 */
  useEffect(() => {
    getShopList()
  }, [getShopList])

  /** 根据店铺id获取店铺商品 */
  useEffect(() => {
    if (!allValues.orderInfo.sysShopId) return
    getShopProductList()
  }, [allValues.orderInfo.sysShopId, getShopProductList])

  //店铺信息
  const formRender0 = () => {
    return (
      <Form {...formProps}>
        <Row gutter={12}>
          <Col {...col1Props}>
            <Form.Item label="手工店铺名称" name={['orderInfo', 'sysShopId']} rules={[{ required: true }]}>
              <Select
                options={shopOption}
                placeholder="请选择手工店铺"
                onChange={() => form.setFieldValue('productList', [])}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  // 订单信息
  const formRender1 = () => {
    return (
      <Form {...formProps}>
        <Row gutter={12}>
          <Col {...col1Props}>
            <Form.Item label="订单号" name={['orderInfo', 'orderNumber']} rules={[{ required: true }]}>
              <Input allowClear placeholder="请输入订单号" />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="Email" name={['orderInfo', 'email']}>
              <Input allowClear placeholder="非必填，买家E-mail" />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="买家指定物流方式" name={['orderInfo', 'shippingMethod']}>
              <Input allowClear placeholder="请输入买家指定物流" />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="发货仓库" name={['orderInfo', 'stash']}>
              {/* <Select  options={[{ value: 1, label: '微仓' }]} /> */}
              <Input disabled allowClear />
            </Form.Item>
          </Col>
          {/* <Col {...col1Props}>
            <Form.Item label="付款状态" name="orderStatus">
              <Select value={1} options={[{ value: 1, label: '已付款' }]} />
            </Form.Item>
          </Col> */}
          <Col {...col1Props}>
            <Form.Item label="下单时间" name={['orderInfo', 'createdAt']}>
              <FormItemDatePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                disabledDate={curr => curr.endOf('day') > dayjs().endOf('day')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  // 收货地址
  const formRender2 = () => {
    return (
      <Form {...formProps}>
        <Row gutter={12}>
          <Col {...col1Props}>
            <Form.Item label="收件人" name={['addressInfo', 'name']} rules={[{ required: true }]}>
              <Input allowClear placeholder="请输入收件人姓名" />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="电话" name={['addressInfo', 'phone']} rules={[{ required: true }]}>
              <Input allowClear placeholder="请输入电话" />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="国家" name={['addressInfo', 'countryCode']} rules={[{ required: true }]}>
              <Select options={countryOpts} filterOption={selectFilterOption} showSearch placeholder="请选择国家" />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="省份" name={['addressInfo', 'province']} rules={[{ required: true }]}>
              <Input allowClear placeholder="请填写省份" />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="城市" name={['addressInfo', 'city']} rules={[{ required: true }]}>
              <Input allowClear placeholder="请填写城市" />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="邮编" name={['addressInfo', 'zip']} rules={[{ required: true }]}>
              <Input allowClear placeholder="请填写邮编" />
            </Form.Item>
          </Col>
          <Col {...col2Props}>
            <Form.Item label="地址1" name={['addressInfo', 'address1']} rules={[{ required: true }]}>
              <Input allowClear placeholder="请填写地址" />
            </Form.Item>
          </Col>
          <Col {...col2Props}>
            <Form.Item label="地址2" name={['addressInfo', 'address2']}>
              <Input allowClear placeholder="请填写地址" />
            </Form.Item>
          </Col>
          <Col {...col1Props}>
            <Form.Item label="公司" name={['addressInfo', 'company']}>
              <Input allowClear placeholder="请填写公司" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  //产品信息
  const formRender3 = () => {
    return (
      <Form {...formProps}>
        <CusTable
          style={{ minHeight: 260 }}
          rowKey={(...[{ skuId }, index]) => skuId || `${skuId}@$@${index}`}
          dataSource={allValues.productList}
          columns={[
            {
              title: 'SKU',
              width: 200,
              render: (_v, { skuId, sku, currentQuantity, price }, index) => {
                return (
                  <Form.Item noStyle>
                    <PlatformProductSelect
                      style={{ width: 180 }}
                      productList={shopProductList}
                      skuId={skuId}
                      sku={sku}
                      disableSkuIds={allValues.productList.map((d: { skuId: string }) => d.skuId)}
                      onPick={product => {
                        const newList = [...allValues.productList]
                        newList.splice(index, 1, {
                          ...product,
                          currentQuantity,
                          price,
                          currencyCode: allValues.currencyCode,
                        })
                        form.setFieldValue('productList', newList)
                      }}
                    />
                  </Form.Item>
                )
              },
            },
            {
              title: t('Delivery.ying-wen-pin-ming'),
              render: (_v, { skuTitle }) => {
                return skuTitle || '--'
              },
            },
            {
              title: t('2-common.shu-liang'),
              width: 200,
              render: (_v, _r, index) => {
                return (
                  <Form.Item noStyle name={['productList', index, 'currentQuantity']} rules={[{ required: true }]}>
                    <InputNumber style={{ width: 180 }} min={1} precision={0} />
                  </Form.Item>
                )
              },
            },
            {
              title: (
                <div className={styles.price}>
                  <div>单价</div>
                  <Form.Item noStyle name="currencyCode" rules={[{ required: true }]}>
                    <Select options={currencyOpts} style={{ width: 100, marginTop: 5 }} />
                  </Form.Item>
                </div>
              ),
              width: 200,
              render: (_v, _r, index) => {
                return (
                  <Form.Item noStyle name={['productList', index, 'price']} rules={[{ required: true }]}>
                    <InputNumber style={{ width: 180 }} min={0} precision={2} />
                  </Form.Item>
                )
              },
            },
            {
              title: (
                <Button
                  size="small"
                  type="primary"
                  ghost
                  icon={<PlusOutlined />}
                  onClick={() => {
                    const list = [...allValues.productList]
                    list.unshift(createEmptyProduct())
                    form.setFieldValue('productList', list)
                  }}
                >
                  {t('2-common.tian-jia')}
                </Button>
              ),
              align: 'center',
              width: 90,
              render: (_v, _r, index) => {
                return (
                  <Popconfirm
                    title={t('2-common.que-ren-yao-shan-chu-ma')}
                    onConfirm={() => {
                      const newList = [...allValues.productList]
                      newList.splice(index, 1)
                      form.setFieldValue('productList', newList)
                    }}
                  >
                    <Button type="link" danger icon={<DeleteOutlined />} />
                  </Popconfirm>
                )
              },
            },
          ]}
        />
      </Form>
    )
  }

  //备注信息
  const formRender4 = () => {
    return (
      <Form {...formProps}>
        <Row gutter={12}>
          <Col {...col2Props}>
            <Form.Item label="买家备注" name={['orderInfo', 'buyerNote']}>
              <Input.TextArea allowClear />
            </Form.Item>
          </Col>
          <Col {...col2Props}>
            <Form.Item label="卖家备注" name={['orderInfo', 'sellerNote']}>
              <Input.TextArea allowClear />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
  //资金信息
  const formRender5 = () => {
    return (
      <Form {...formProps} layout="horizontal">
        <Row gutter={12} justify="end">
          <Col>
            <Form.Item label="订单金额">
              <Input allowClear disabled value={orderShopTotalPrice} suffix={allValues.currencyCode} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12} justify="end">
          <Col>
            <Form.Item label="订单运费" name={['orderInfo', 'sellFreight']} rules={[{ required: true }]}>
              <InputNumber min={0} precision={2} suffix={allValues.currencyCode} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12} justify="end">
          <Col>
            <Form.Item label="总金额">
              <Input value={orderTotalPrice} disabled suffix={allValues.currencyCode} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }

  // 操作
  const actionRender = () => {
    return (
      <>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          loading={loading}
          onClick={async () => {
            form.submit() // 触发 scrollToFirstError
            await form.validateFields()
            if (!validateProductList()) return
            Modal.confirm({
              title: t('LocalProductList.que-ren-yao-xin-zeng-ma'),
              onOk: async () => {
                const productList = allValues.productList.map(
                  (item: { currencyCode: any; currentQuantity: any; price: any; productId: any; skuId: any }) => {
                    return {
                      currencyCode: item.currencyCode,
                      currentQuantity: item.currentQuantity,
                      price: item.price,
                      shopProductId: item.productId,
                      shopSkuId: item.skuId,
                    }
                  },
                )
                const countryIndex = countryOpts.findIndex(item => item.value === allValues.addressInfo?.countryCode)
                const data = {
                  orderInfo: allValues.orderInfo,
                  addressInfo: { ...allValues.addressInfo, country: countryOpts[countryIndex].label },
                  productList,
                }
                try {
                  setLoading(true)
                  await manualOrderCreate(data)
                  message.success(t('2-common.cao-zuo-cheng-gong'))
                  getList()
                  refreshTabShowCounts()
                  onReturn()
                } finally {
                  setLoading(false)
                }
              },
            })
          }}
        >
          {t('2-common.xin-zeng')}
        </Button>
      </>
    )
  }

  return (
    <div className={styles.box}>
      <div className={styles.main}>
        <>
          <Anchor
            className={styles.main_left}
            affix={false}
            getContainer={() => scrollRef.current || window}
            onClick={e => e.preventDefault()}
            items={['店铺信息', '订单信息', '收货地址', '产品信息', '备注信息', '资金信息'].map((name, index) => ({
              key: name,
              href: `#${idPrefix}_${index}`,
              title: name,
            }))}
          />
          <div className={styles.main_right} ref={scrollRef}>
            <section id={`${idPrefix}_0`}>
              <Divider orientation="left" orientationMargin={0}>
                <b>店铺信息</b>
              </Divider>
              {formRender0()}
            </section>
            <section id={`${idPrefix}_0`}>
              <Divider orientation="left" orientationMargin={0}>
                <b>订单信息</b>
              </Divider>
              {formRender1()}
            </section>

            <section id={`${idPrefix}_1`}>
              <Divider orientation="left" orientationMargin={0}>
                <b>收货地址</b>
              </Divider>
              {formRender2()}
            </section>

            <section id={`${idPrefix}_2`}>
              <Divider orientation="left" orientationMargin={0}>
                <b>产品信息</b>
              </Divider>
              {formRender3()}
            </section>

            <section id={`${idPrefix}_3`}>
              <Divider orientation="left" orientationMargin={0}>
                <b>备注信息</b>
              </Divider>
              {formRender4()}
            </section>
            <section id={`${idPrefix}_4`}>
              <Divider orientation="left" orientationMargin={0}>
                <b>资金信息</b>
              </Divider>
              {formRender5()}
            </section>
          </div>
        </>
      </div>
      <div className={styles.footer}>
        <Space>
          <Button onClick={() => onReturn()}>{t('2-common.fan-hui')}</Button>
          {actionRender()}
        </Space>
      </div>
    </div>
  )
}
