import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'
import { useSessionStorageState } from 'ahooks'
import { Button, Input, Select, Space } from 'antd'

import { ShopSelect } from '../../components/ShopSelect'
import { apiTrackExport } from '../apis'
import { Ctx } from '../Ctx'

const trackSearhDataDefaultVal: {
  numberType: string
  orderOrWayBillNum?: string | null
} = {
  numberType: 'wayBillNumber',
  orderOrWayBillNum: null,
}

/** 筛选条件 */
export const Filter = memo(function Filter() {
  const [numberType, setNumberType] = useState<string>('wayBillNumber')
  const { t } = useTranslation()
  const isEmployee = useContextSelector(Ctx, v => v.isEmployee)
  const param = useContextSelector(Ctx, v => v.param)
  const sysShopIds = useContextSelector(Ctx, v => v.sysShopIds)
  const getList = useContextSelector(Ctx, v => v.getList)
  const setState = useContextSelector(Ctx, v => v.setState)
  const [trackSearhData = trackSearhDataDefaultVal, setTracSearhData] = useSessionStorageState<
    typeof trackSearhDataDefaultVal
  >('oms_orderTrack', { defaultValue: trackSearhDataDefaultVal })

  const handleMaterialExport = async () => {
    await apiTrackExport({ sysShopIds })
  }
  useEffect(() => {
    if (trackSearhData?.orderOrWayBillNum) {
      setState({ [`${trackSearhData.numberType}`]: trackSearhData.orderOrWayBillNum })
      getList({ [`${trackSearhData.numberType}`]: trackSearhData.orderOrWayBillNum, page: 1 })
    }
  }, [])

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', flex: '1 1' }}>
      <Space size="middle">
        {!isEmployee && (
          <ShopSelect
            multiple
            value={sysShopIds}
            onChange={val => {
              const sysShopIds = val.length ? val : undefined
              getList({ sysShopIds, page: 1 })
            }}
          />
        )}
        <Space.Compact>
          <Select
            defaultValue={trackSearhData.numberType}
            options={[
              {
                value: 'wayBillNumber',
                label: '运单号',
              },
              {
                value: 'orderNumber',
                label: '订单号',
              },
            ]}
            onChange={value => {
              setNumberType(value)
              setTracSearhData({ ...trackSearhData, numberType: value })
            }}
          />
          <Input.Search
            style={{ minWidth: 400 }}
            placeholder="支持多单号查询 ，查询用逗号隔开，一次最多查询40个"
            enterButton
            allowClear
            value={trackSearhData.orderOrWayBillNum ?? undefined}
            onChange={e => {
              const key = numberType === 'wayBillNumber' ? 'orderNumber' : 'wayBillNumber'
              setState({
                [`${numberType}`]: e.target.value.trim(),
                [key]: undefined,
              })
              setTracSearhData({ ...trackSearhData, orderOrWayBillNum: e.target.value.trim() })
            }}
            onSearch={(val, _e, info) => {
              val = val.trim()
              if (info?.source === 'clear' && !param?.orderNumber && !param?.wayBillNumber) return
              getList({ [`${numberType}`]: val, page: 1 })
            }}
          />
        </Space.Compact>
      </Space>
      <Button type="primary" onClick={handleMaterialExport}>
        导出运单号
      </Button>
    </div>
  )
})
