import { createRef } from 'react'
import { createContext } from 'use-context-selector'
import _ from 'lodash'

import { createPopupSlot } from '@/services/popupSlot'
import { DEFAULT_PAGE_SIZE } from '@/utils/pagination'
import { TAB_TYPE } from '../consts'
import { ICtxState, ICtxVal } from './interface'

export const popupSlot = createPopupSlot()

export const createInitCtxState = (
  mergeState?: Partial<ICtxState>,
): { [P in keyof Required<ICtxState>]: ICtxState[P] } => {
  return {
    orderStatus: undefined,
    sysShopIds: undefined,
    orderNumber: undefined,
    wayBillNumber: undefined,
    page: 1,
    size: DEFAULT_PAGE_SIZE,
    selectedRowKeys: [],
    expandedRowKeys: [],
    ...mergeState,
  }
}

export const initCtxState = createInitCtxState()

export const initCtxVal: ICtxVal = {
  ...initCtxState,
  isEmployee: false,
  tabType: TAB_TYPE.待审核,
  activeTab: '',
  tabShowCounts: [],
  reqData: { tabType: TAB_TYPE.待审核, page: 1, size: DEFAULT_PAGE_SIZE },
  loading: false,
  data: undefined,
  param: undefined,
  getList: _.noop,
  changeRecord: _.noop,
  deleteRecord: _.noop,
  setActiveTab: _.noop,
  refreshTabShowCounts: _.noop,
  tableRef: createRef(),
  setState: _.noop,
  ctxValRef: {
    get current() {
      return initCtxVal
    },
  },
}

export const CTX_STATE_KEYS = Object.keys(initCtxState)

export const Ctx = createContext<ICtxVal>(initCtxVal)
