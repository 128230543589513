import { memo } from 'react'
import dayjs from 'dayjs'
import type { ColumnsType } from 'antd/es/table'

import { CusTable } from '@/components/CusTable'
import { Cells, useTableProps } from '../Ctx'
import { useColumnInfo } from '../Ctx/useColumnInfo'
import { IOrder } from '../interface'
const format = `MM-DD HH:mm`
export const OrderTable = memo(function OrderTable() {
  const columnInfo = useColumnInfo()
  const tableProps = useTableProps()

  const columns: ColumnsType<IOrder> = [
    {
      ...columnInfo.orderNumber,
      render: (_v, record) => <Cells.OrderNumberCell record={record} />,
    },
    {
      ...columnInfo.wayBillNumber,
      render: (_v, record) => <>{record?.wayBillNumber}</>,
    },
    {
      ...columnInfo.channel,
      render: (_v, record) => <Cells.ChannelCell record={record} />,
    },
    {
      ...columnInfo.orderAddress,
      render: (_v, record) => <Cells.OrderAddressCell record={record} />,
    },
    {
      ...columnInfo.fulfillment,
      render: (_v, record) => <Cells.FulfillmentCell record={record} />,
    },
    {
      ...columnInfo.trackNews,
      render: (_v, record) => <Cells.OrderTrackRouteCell record={record} />,
    },
    {
      title: '异常信息',
      width: 130,
      render: (_v, record) => <>{record?.trackFailDesc}</>,
    },
    {
      title: '运输天数',
      width: 85,
      render: (_v, record) => <>{record.trackTransitDays}</>,
    },
    {
      title: '下单时间',
      width: 107,
      render: (_v, record) => <>{record.createdAt ? dayjs(record.createdAt).format(format) : ''}</>,
    },
    {
      title: '发货时间',
      width: 107,
      render: (_v, record) => <>{record.deliveryTime ? dayjs(record.deliveryTime).format(format) : ''}</>,
    },
    {
      ...columnInfo.action,
      render: (_v, record) => <Cells.ActionCell record={record} />,
    },
  ]

  return <CusTable {...tableProps} columns={columns} />
})
