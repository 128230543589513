import { useTranslation } from 'react-i18next'
import { useContextSelector } from 'use-context-selector'

import { useGlobalState } from '@/globalStore/globalStore'
import { TAB_TYPE, TAB_TYPE2 } from '../consts'
import { Ctx } from './Ctx'
import styles from './styles.module.less'

const cActionColWidthConfig = {
  [TAB_TYPE.待匹配]: 53,
  [TAB_TYPE.待付款]: 53,
  [TAB_TYPE.待审核]: 80,
  [TAB_TYPE.待处理]: 80,
  [TAB_TYPE.已搁置]: 53,
  [TAB_TYPE.待发货]: 53,
  [TAB_TYPE.已发货或订单追踪]: 80,
  [TAB_TYPE.已取消]: 53,
  [TAB_TYPE.非ShipO]: 53,
  [TAB_TYPE.运单_无货]: 53,
  [TAB_TYPE.运单_有货]: 53,
  [TAB_TYPE.异常订单]: 53,
}
const eActionColWidthConfig = {
  [TAB_TYPE2.待审核]: 80,
  [TAB_TYPE2.待预报]: 80,
  [TAB_TYPE2.待打印]: 53,
  [TAB_TYPE2.待推送]: 53,
  [TAB_TYPE2.待打包]: 53,
  [TAB_TYPE2.已发货或运单追踪]: 80,
  [TAB_TYPE2.已取消]: 53,
  [TAB_TYPE2.异常履约单]: 53,
}

/** 列态信息 */
export const useColumnInfo = () => {
  const { t } = useTranslation()
  const isEn = useGlobalState(s => s.lang === 'en')

  const tabType = useContextSelector(Ctx, v => v.tabType)
  const isEmployee = useContextSelector(Ctx, v => v.isEmployee)

  const getActionWidth = () => {
    let val = 0
    if (!isEmployee) val = (cActionColWidthConfig[tabType as TAB_TYPE] || 75) + 10
    else val = (eActionColWidthConfig[tabType as TAB_TYPE2] || 75) + 10
    return isEn && val < 75 ? 75 + 10 : val
  }

  return {
    /** 订单号 */
    orderNumber: {
      title: t('Order.ding-dan-hao'),
      width: 130,
    },
    wayBillNumber: {
      title: '运单号',
      width: 130,
    },
    trackNews: {
      title: '最新信息',
      width: 200,
    },
    /** 商品信息 */
    productInfo: {
      title: t('Order.shang-pin-xin-xi'),
      width: 130,
    },
    /** 渠道 */
    channel: {
      title: t('Order.qu-dao'),
      width: 130,
    },
    /** 订单金额 */
    currentTotalPrice: {
      title: t('Order.ding-dan-jin-e'),
      width: 109,
      align: 'right',
    },
    /** 收件人 */
    orderAddress: {
      title: t('Order.shou-jian-ren-guo-jia-di-qu'),
      width: 190,
    },
    /** 订单时间 */
    orderTime: {
      title: t('Order.ding-dan-shi-jian'),
      width: 107,
    },
    /** 物流方式 */
    fulfillment: {
      title: t('Order.wu-liu-fang-shi'),
      width: 150,
    },
    /** 状态 */
    orderStatus: {
      title: t('2-common.zhuang-tai'),
      width: 145,
      onCell: () => ({ style: { whiteSpace: 'nowrap' } }),
    },
    action: {
      title: t('2-common.cao-zuo'),
      width: getActionWidth(),
      align: 'center',
      fixed: 'right',
      onCell: () => ({ className: styles.actionCell }),
    },
  } as const
}
